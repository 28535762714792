<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row class="justify-content-end">
        <!-- Search -->
        <!-- <b-col
          cols="12"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.find')"
            />
          </div>
        </b-col> -->

        <!-- Entry -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
          <!-- <b-button
            variant="primary"
            :to="{ name: 'apps-transactions-purchase-request-add'}"
          >
            <feather-icon icon="PlusIcon" /> {{ $t('apps.transactions.purchase.request.actions.create') }}
          </b-button> -->
        </b-col>
      </b-row>
    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :fields="tableColumns"
      :items="itemLists"
      responsive
      primary-key="id"
      show-empty
      :busy="isBusy"
      :empty-text="$t('globalSingular.noMatching')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <template #head()="{ field }">
        <table-search-column
          :field="field"
          :fields="tableColumns"
          :focused-field="focusedSearchField"
          @set-focused-field="(value) => focusedSearchField = value"
          @search-data="(params) => searchData(params)"
        />
      </template>

      <!-- Column: code -->
      <template #cell(code)="{item}">
        <b-link
          :to="{ name: 'apps-transactions-purchase-request-detail', params: { id: item.id } }"
          class="font-weight-bolder"
        >
          <span class="font-weight-bolder d-block text-nowrap">
            {{ item.code }}
          </span>
        </b-link>
      </template>

      <!-- Column: Lab -->
      <template #cell(label)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
          {{ item.contact.label }}
        </span>
        <small class="font-italic">{{ item.remark }}</small>
      </template>

      <!-- Column: Amount -->
      <template #cell(amount)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
          {{
            formatCurrency(item.sum_of_bill_amount)
          }}
        </span>
      </template>

      <!-- Column: Tags -->
      <template #cell(tags)="{ item }">
        <div v-if="(item.tags || []).length > 0" class="d-flex">
          <b-badge v-for="(tag, index) in item.tags" :key="index" pill variant="light-primary">{{ tag.name }}</b-badge>
        </div>
        <span v-else>-</span>
      </template>

      <!-- Column: audit -->
      <!-- {{ item.updated_process }} -->
      <template #cell(audit)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
        {{ item.updated_process }}  By {{ item.getUser.user_name }}
        </span>
        <small class="font-italic">{{ new Date(item.updated_at).toDateString() }}</small>
      </template>

      <!-- Column: Priority -->
      <template #cell(priority)="{ item, value }">
        <b-badge
          v-if="value"
          pill
          :variant="`${value === 'NORMAL' ? 'light-primary' : 'light-danger'}`"
        >{{ item.priority }}</b-badge>
        <span v-else>-</span>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('globalSingular.showing') }} {{ dataMeta.from }} {{ $t('globalSingular.to') }} {{ dataMeta.to }} {{ $t('globalSingular.of') }} {{ dataMeta.of }} {{ $t('globalSingular.entries') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>


<script>
import {
  BCard,
  // BButton,
  BRow,
  BCol,
  BTable,
  BLink,
  BPagination,
  BSpinner,
  BBadge
} from 'bootstrap-vue'
import TableSearchColumn from "@/components/TableSearchColumn.vue"

import { formatCurrency } from '@/utils/formatter'
// to access i18n
import useLocalization from '@/comp-functions/utils/useLocalization'
import { ref } from '@vue/composition-api'
import useListTablePage from '@/comp-functions/useListTablePage'


export default {
  components: {
    BCard,
    // BButton,
    BRow,
    BCol,
    BTable,
    BLink,
    BPagination,
    BSpinner,
    BBadge,
    TableSearchColumn
  },


  setup () {
    const { $t } = useLocalization()

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      focusedSearchField,
      customSearchQuery,
      fetchLists,
      loadPage,
      changePerPage,
    } = useListTablePage({ url: "transaction/purchase/request?is_approved=false" })

    // Table Handlers
    const tableColumns = ref([
      {
        key: 'code',
        label: $t('globalSingular.code'),
        search: { type: 'text', value: '' }
      },
      {
        key: 'date',
        label: $t('globalSingular.date'),
        sortable: true ,
        search: { type: 'date', value: '' }
      },
      {
        key: 'priority',
        label: $t('globalSingular.priority'),
        thStyle: { minWidth: '100px' },
        sortable: true,
        formatter: (value) => value ? `${value}`.toUpperCase() : null,
        search: {
          type: 'select',
          value: '',
          options: [
            { id: 'NORMAL', label: 'Normal' },
            { id: 'URGENT', label: 'Urgent' },
            { id: 'NULL', label: 'Empty (-)' }
          ]
        }
      },
      {
        key: 'getOffice.label',
        label: $t('globalSingular.branch'),
        formatter: (value) => value || '-',
        search: {
          type: 'select',
          value: '',
          key: 'office_id',
          url: 'value/getBranch'
        }
      },
      {
        key: 'remark',
        label: $t('globalSingular.description'),
        thStyle: { minWidth: '300px' },
        formatter: (value) => value || '-',
        search: { type: 'text', value: '' }
      },
      {
        key: 'tags',
        label: $t('globalSingular.tags'),
        thStyle: { minWidth: '200px' },
        search: { type: 'text', value: '' }
      },
      {
        key: 'audit',
        label: $t('globalSingular.audit'),
        search: { type: 'text', value: '' }
      }
    ])

    const searchData = async (queryParams) => {
      customSearchQuery.value = queryParams
      await loadPage()
    }


    return {
      formatCurrency,
      tableColumns,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchData,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists,
      changePerPage,
      loadPage,
      focusedSearchField,
    }
  }
}
</script>
